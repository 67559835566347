<template>
  <div class="white-background" id="about-dear-mama">
    <v-row class="text-center d-flex justify-center">
      <v-col class="mt-6 mb-10" cols="10">
        <h1 class="mt-5 mb-1 display-1">Dear Mama: approfondimenti</h1>
        <p class="font-weight-light title mt-3">
          Condizioni essenziali allo sviluppo psicoaffettivo e corporeo del
          bambino sono sicuramente la stabilità, la continuità, la sicurezza e
          l’affidabilità di figure genitoriali amorevoli. La qualità delle cure
          genitoriali lascia inevitabilmente un’impronta nella vita psichica di
          ogni essere umano e le prime esperienze vissute dal bambino, se non
          adeguate al suo livello di sviluppo e non inserite nell’ambito di un
          positivo rapporto genitoriale, si delineano come potenziali fattori
          traumatici.
        </p>
        <p class="font-weight-light title mt-3">
          L’adozione è un istituto giuridico che permette ad un soggetto (detto
          adottante) di trattare ufficialmente un altro soggetto (detto
          adottato) come figlio. (...)
        </p>

        <v-btn @click="openModal" class="mt-5" outlined color="#222222"
          >Continua a leggere</v-btn
        >
        <p class="font-weight-light title">
          <!-- <span class="font-weight-bold">something important</span> to catch the attention. -->
        </p>
      </v-col>
    </v-row>
    <v-dialog width="70%" v-model="isModalOpen">
      <v-col cols="12" sm="12" md="12" lg="12" class="pa-0">
        <v-card class="pa-5">
          <div fluid>
            <v-row class="text-center d-flex justify-center">
              <v-col class="mt-6 mb-10 px-16" cols="12">
                <h1 class="mt-5 mb-1 display-1">Adozioni</h1>
                <div class="font-weight-light title text-justify">
                  <p class="mt-6">
                    Condizioni essenziali allo sviluppo psicoaffettivo e
                    corporeo del bambino sono sicuramente la stabilità, la
                    continuità, la sicurezza e l’affidabilità di figure
                    genitoriali amorevoli. La qualità delle cure genitoriali
                    lascia inevitabilmente un’impronta nella vita psichica di
                    ogni essere umano e le prime esperienze vissute dal bambino,
                    se non adeguate al suo livello di sviluppo e non inserite
                    nell’ambito di un positivo rapporto genitoriale, si
                    delineano come potenziali fattori traumatici.
                  </p>
                  <p class="mt-3">
                    L’adozione è un istituto giuridico che permette ad un
                    soggetto (detto adottante) di trattare ufficialmente un
                    altro soggetto (detto adottato) come figlio. Si tratta
                    generalmente di minorenni, rimasti senza genitori naturali o
                    non riconosciuti da questi o che si trovino in una
                    situazione di abbandono.
                  </p>
                  <p class="mt-3">
                    L’affidamento o affido familiare fa invece riferimento ad
                    una istituzione dell’ordinamento civile italiano che si basa
                    su un provvedimento temporaneo rivolto a bambini e a ragazzi
                    fino ai diciotto anni, di nazionalità italiana o straniera,
                    che si trovino in situazioni di instabilità familiare.
                    Tramite l’affido, il minorenne viene accolto presso una
                    famiglia che ne fa richiesta e, ove ciò non sia possibile, è
                    consentito l’inserimento del minorenne in una comunità di
                    assistenza pubblica o privata.
                  </p>
                  <p class="mt-3">
                    La procedura di adozione prevede una serie di step: prima di
                    tutto occorre essere considerati idonei da parte del
                    Tribunale per i minorenni, che si serve per questo dei
                    servizi socio-assistenziali presenti sul territorio.
                  </p>
                  <p class="mt-3">
                    L’idoneità genitoriale è definita da tre requisiti
                    fondamentali:
                  </p>
                  <ul>
                    <li>
                      la coppia deve essere unita in matrimonio da almeno tre
                      anni, senza nessuna separazione di fatto. In alternativa,
                      deve aver convissuto, prima del matrimonio, in modo
                      stabile e continuativo, per almeno tre anni;
                    </li>
                    <li>
                      la differenza di età tra adottato e adottante deve essere
                      compresa tra i 18 ed i 45 anni;
                    </li>
                    <li>
                      la coppia deve essere idonea ad educare, istruire e
                      mantenere i minorenni che intende adottare.
                    </li>
                  </ul>
                  <p class="mt-3">
                    Il minorenne, a sua volta, deve essere dichiarato in stato
                    di adottabilità, definito dalla presenza di uno stato di
                    abbandono, dovuto alla mancanza di assistenza morale o
                    materiale da parte di genitori o dei parenti che dovrebbero
                    provvedervi.
                  </p>
                  <p class="mt-3">
                    Per effetto dell’adozione, l’adottato acquista lo stato di
                    “figlio” degli adottanti, dei quali assume e trasmette il
                    cognome. Con l’adozione cessano i rapporti giuridici
                    intercorrenti tra l’adottato e la famiglia di origine,
                    eccezion fatta per alcune situazioni. Inoltre, i figli
                    adottivi sono equiparati ai figli legittimi nei diritti
                    ereditari. Il figlio adottato, in base alla normativa
                    sull’adozione, ha diritto, raggiunto il venticinquesimo anno
                    di età, ad accedere a informazioni riguardanti la sua
                    origine e l’identità dei genitori biologici. È dato lo
                    stesso diritto a 18 anni solo in caso di gravi e comprovati
                    motivi di salute psico-fisica.
                  </p>
                  <p class="mt-3">
                    Purtroppo in alcune famiglie lo spazio di accettazione è
                    solo fisico ma non mentale e alcune coppie non sono in grado
                    di rispondere in maniera adeguata alle esigenze del nuovo
                    arrivato e non sono in grado di adattarsi alla nuova realtà.
                    Si palesa per questi bambini il rischio di ritornare negli
                    Istituti, con il tristissimo incubo di un ennesimo
                    abbandono. Spesso sono storie intrise di dolore che
                    rimangono senza orecchie che ascoltino o occhi che vedano.
                  </p>
                  <p class="mt-3">
                    Gli aspiranti all’adozione non vantano un “diritto” ad
                    ottenere un bambino ma possono solo esprimere la loro
                    “disponibilità” ad adottarne uno. Infatti, l’istituto
                    dell’adozione ha per fine di soddisfare il diritto di ogni
                    bambino ad avere una famiglia e non viceversa.
                  </p>
                  <p class="mt-3">
                    I bambini hanno il diritto a non essere travolti dalle
                    sofferenze dei genitori ma di continuare ad essere amati e
                    di vivere la loro età all’interno del loro nucleo familiare
                    o, qualora questo non fosse possibile, in un ambiente
                    ospitale che sappia rispettare i loro tempi e sappia
                    ascoltarli.
                  </p>
                  <p class="mt-3">
                    In particolare esistono diversi tipi di comunità in grado di
                    ospitare persone di minore età in stato di necessità:
                  </p>
                  <ul>
                    <li>
                      comunità educative, caratterizzate dalla presenza di
                      educatori professionali, che accompagnano i minorenni nel
                      loro percorso formativo e di crescita;
                    </li>
                    <li>
                      comunità familiari (meglio conosciute come Case Famiglia)
                      in cui vi è la presenza stabile di uno o più adulti, che
                      li accolgono mediante l’affido temporaneo;
                    </li>
                    <li>
                      case madri-figli, che ospitano nuclei monoparentali
                      (madre-bambino);
                    </li>
                    <li>
                      comunità alloggio e appartamenti destinate ad adolescenti
                      e maggiorenni che sperimentano percorsi di semi-autonomia
                      e autonomia;
                    </li>
                    <li>case multiutenza e servizi di pronta accoglienza.</li>
                  </ul>
                  <p class="mt-3">
                    Non si ereditano solo geni ma storie spesso tristi e
                    dolorose. Questi bambini meritano certamente il
                    riconoscimento dei diritti fondamentali dell’essere umano,
                    ma soprattutto quell’amore e quell’affetto necessari per
                    crescere serenamente.
                  </p>
                </div>
                <p class="mt-6 font-weight-bold">Pietro Ferrara</p>
                <p class="mt-6">
                  Pediatria numero 9 - settembre 2019 Pensiero Scientifico
                  Editore
                </p>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-col>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "AboutSection",

  data() {
    return {
      isModalOpen: false,
    };
  },

  methods: {
    openModal() {
      this.isModalOpen = true;
    },
  },
};
</script>

<style>
.white-background {
  background-color: #ffffff !important;
}
</style>

